/* show ol styles in builder disabled by tailwind */
.builder-block > span > ol,
.builder-block > p > ol {
  list-style: decimal;
  padding-left: 22px;
}

/* show ul styles in builder disabled by tailwind */
.builder-block > p > ul,
.builder-block > span > ul {
  position: relative;
}

.builder-block > p > ul,
.builder-block > span > ul {
  position: relative;
}

.builder-block > p > ul li,
.builder-block > span > ul li {
  padding-left: 0.7em;
}

.builder-block > p > ul li::before,
.builder-block > span > ul li::before {
  content: '\2022';
  color: var(--wh-color_slate);
  font-weight: bold;
  display: inline-block;
  width: 1em;
  height: 100%;
  left: 0;
  position: absolute;
}

/* cap max with on builder blocks */
.builder-block {
  max-width: 100%;
}

@media (width >= 640px) {
  .builder-block {
    max-width: 100vw;
    box-sizing: border-box !important;
  }
}

.card-section > .builder-block {
  margin: 0 !important;
  width: 100% !important;
  padding: 10px 0;
}

.market-card {
  height: 500px;
  display: block;
}

.market-card > a {
  display: block;
  height: 100%;
  background-color: white;
  border-radius: 9px;
}

.market-card > div {
  display: block;
  height: 100%;
  background-color: white;
  border-radius: 9px;
}

.market-card > a > div {
  height: 100%;
}

@media (width >= 768px) {
  .card-section > .builder-block {
    margin: auto !important;
    width: auto !important;
  }
}
